import Layout from '@/components/Layout/Layout'
import SEO from '@/components/SEO/Seo'
import React from 'react'
import { graphql } from 'gatsby';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import GeneralTextBlock from '../../components/GeneralTextBlock';
import TypesOfTyreCards from '../../components/Cards/TypesOfTyreCard';

import './TypesOfTyreIndex.scss';

interface Props {
    data: {
        strapi: {
            typesOfTyreIndex: {
                HeroSection: {
                    activeLozenge: string;
                    className: string;
                    displayIcon: string;
                    headerText: string;
                    headerPrefix: string;
                    lozengeText: string;
                    iconImg: {
                        url: string;
                    }
                    mainImg: {
                        url: string;
                    }
                }
                Seo: {
                    description: string;
                    title: string;
                }
                TextBlock: {
                    textBlock: string;
                }
                TypesOfTyreCard: {
                    map: (type) => any;
                    cardImg: {
                        url: string;
                    }
                    id: string;
                    cardLink: string;
                    introText: string;
                    title: string;
                }
            }
        }
    }
}

const TypesOfTyreIndex = ({data}: Props) => {

    const { HeroSection, TextBlock, TypesOfTyreCard, Seo } = data.strapi.typesOfTyreIndex
    
    return (
        <>
            <SEO/>
            <Layout>
                <GeneralHeroSec data={HeroSection} />
                <div className='tyreIndexContainer'>
                    <GeneralTextBlock textData={TextBlock} />
                </div>
                <section className="tyreTypesCont">
                    <h3 className='tyreTypeHeader'>All Types of Tyres</h3>
                    <div className='listOfTyreTypes'>
                        {TypesOfTyreCard.map( (type, id) => (
                            <TypesOfTyreCards key={id} data={type}/>
                        ))}
                    </div>
                </section>
                
                <SearchWidgetContainer />
            </Layout>
        </>
    )
}

export const typesOfTyreIndex = graphql`
query TypesOfTyreIndex {
    strapi {
      typesOfTyreIndex(publicationState: PREVIEW) {
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerText
          headerPrefix
          lozengeText
          iconImg {
            url
          }
          mainImg {
            url
          }
        }
        Seo {
          description
          title
        }
        TextBlock {
          textBlock
        }
        TypesOfTyreCard {
          cardImg {
            url
          }
          id
          cardLink
          introText
          title
        }
      }
    }
}
`

export default TypesOfTyreIndex
