import React, { ReactElement } from 'react';
import parse from 'react-html-parser';

import './TypesOfTyreCard.scss';

interface Props {
  data: {
    introText: string;
    title: string;
    cardLink: string;
    cardImg: {
        url: string;
    }
  };
}

const BlogPostCard = (props: Props): ReactElement => {
  
  const { introText, title, cardImg, cardLink } = props.data;  

  return (
    <>
      <li className="typesOfTyreCard">
        <a className="cardLink" href={cardLink}>
          <img className="tyreTypeImg" src={cardImg.url} alt="" />
          <div className="cardText">
            <h5>{title}</h5>
            <div className='introText'> 
            {parse(introText)}
            </div>
            <p className="redText">Find out More</p>
          </div>
        </a>
      </li>
    </>
  );
};

export default BlogPostCard;
